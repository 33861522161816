




























































































































































































































import {
  defineNuxtComponent,
  PropType,
  computed
} from '~/utils/nuxt3-migration'
import { Classified } from '~/models/classified/types'
import CPrice from '~/components/shared/configurable/CPrice.vue'
import { ciCheckCircle } from '~/icons/source/light/check-circle'
import { ciAngleRight } from '~/icons/source/regular/angle-right'
import { ciClock } from '~/icons/source/regular/clock'
import { ciLocationPin } from '~/icons/source/regular/location-pin'
import { cappedTimeDistanceFromNow } from '~/utils/date'
import { useI18n } from '~/compositions/i18n'
import { LocaleCode } from '~/models/locale/types'
import { CategoryId } from '~/models/category/types'

export default defineNuxtComponent({
  components: {
    CPrice
  },
  props: {
    classified: {
      type: Object as PropType<Classified>,
      required: true
    }
  },
  setup(props) {
    const { locale } = useI18n()

    const isRentalClassified = props.classified.category_ids.includes(
      CategoryId.RENTALS
    )

    const lastModifiedDate = computed(() =>
      cappedTimeDistanceFromNow(props.classified.modified, 60, {
        locale: locale as LocaleCode,
        addPrefix: false
      })
    )

    return {
      ciCheckCircle,
      ciAngleRight,
      ciClock,
      ciLocationPin,
      lastModifiedDate,
      isRentalClassified
    }
  }
})
