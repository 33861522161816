











































































import {
  defineNuxtComponent,
  ref,
  useFetch,
  useFetchState,
  useRouter
} from '~/utils/nuxt3-migration'
import CNewBadge from '~/components/shared/configurable/badge/CNewBadge.vue'
import CMenuItem from '~/components/shared/configurable/menu/CMenuItem.vue'
import CIcon from '~/components/shared/configurable/CIcon.vue'
import { useI18n } from '~/compositions/i18n'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { useDeps } from '~/compositions/dependency-container'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import RouteGuardService from '~/services/RouteGuardService'
import { NavigationLink, NavigationMenu } from '~/models/header/navigation-menu'
import { CategoryId } from '~/models/category/types'
import { AccessToggleName } from '~/models/user/access-toggles'
import { defineComponentTranslations } from '~/utils/i18n'
import CExternalLinkMediatorModal from '~/components/shared/configurable/modal/CExternalLinkMediatorModal.vue'

export default defineNuxtComponent({
  components: {
    CMenuItem,
    CNewBadge,
    CIcon,
    CExternalLinkMediatorModal
  },
  setup() {
    const { t } = useI18n()
    const router = useRouter()
    const [legacyUrlService, routeGuardService] = useDeps(
      LegacyUrlService,
      RouteGuardService
    )
    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const userHasAccessTo = userGetters('userHasAccessTo')
    const userRegisterLink = legacyUrlService.getRegisterUrl()

    const externalLinkMediatorUrl = ref('')
    const showExternalLinkMediatorModal = ref(false)
    const activeDropdown = ref<number | null>(null)
    const menu = useFetchState(
      'menu',
      ref<NavigationMenu[]>([
        {
          title: t('search'),
          name: 'search',
          links: [] as NavigationLink[][],
          dropdownPosition: 'center'
        },
        {
          title: t('offer'),
          name: 'offer',
          links: [] as NavigationLink[][],
          dropdownPosition: 'center',
          hide: !routeGuardService.userCanCreateClassified()
        },
        {
          title: t('services'),
          name: 'services',
          links: [] as NavigationLink[][],
          dropdownPosition: 'center'
        },
        {
          title: t('information'),
          name: 'information',
          subtitles: [t('general'), t('dealers'), t('private sellers')],
          links: [] as NavigationLink[][],
          dropdownPosition: 'center'
        }
      ])
    )

    useFetch(populateSubMenus)

    async function populateSubMenus() {
      const [
        ciVehicles,
        ciPartsAccessories,
        ciRealEstate,
        ciXyma,
        ciJobs,
        ciVehicleRentals,
        ciDeals,
        ciCarService,
        ciSearch,
        ciCar,
        ciMotorcycle,
        ciTruck,
        ciCaravan,
        ciBoat,
        ciBicycle,
        ciRc,
        ciWantedParts
      ] = await Promise.all([
        import('~/icons/source/duotone/vehicles').then(m => m.ciVehicles),
        import('~/icons/source/duotone/parts-accessories').then(
          m => m.ciPartsAccessories
        ),
        import('~/icons/source/duotone/real-estate').then(m => m.ciRealEstate),
        import('~/icons/source/duotone/xyma').then(m => m.ciXyma),
        import('~/icons/source/duotone/jobs').then(m => m.ciJobs),
        import('~/icons/source/brand/vehicle-rentals').then(
          m => m.ciVehicleRentals
        ),
        import('~/icons/source/duotone/deals').then(m => m.ciDeals),
        import('~/icons/source/duotone/car-service').then(m => m.ciCarService),
        import('~/icons/source/duotone/search').then(m => m.ciSearch),
        import('~/icons/source/duotone/car').then(m => m.ciCar),
        import('~/icons/source/duotone/motorcycle').then(m => m.ciMotorcycle),
        import('~/icons/source/duotone/truck').then(m => m.ciTruck),
        import('~/icons/source/duotone/caravan').then(m => m.ciCaravan),
        import('~/icons/source/duotone/boat').then(m => m.ciBoat),
        import('~/icons/source/duotone/bicycle').then(m => m.ciBicycle),
        import('~/icons/source/duotone/rc').then(m => m.ciRc),
        import('~/icons/source/duotone/wanted-parts').then(m => m.ciWantedParts)
      ])

      menu.value[0].links = [
        [
          {
            title: t('vehicles'),
            icon: ciVehicles,
            url: router.resolve({ name: '__classifieds_quick_search' }).href,
            useNuxtLink: true
          },
          {
            title: `${t('parts')} & ${t('accessories')}`,
            icon: ciPartsAccessories,
            url: '/quick-search/parts/for-cars/',
            useNuxtLink: true
          },
          {
            title: t('wanted_parts'),
            icon: ciWantedParts,
            url: router.resolve({
              name: '__classifieds_search_wanted_parts'
            }).href,
            useNuxtLink: true,
            useAnonGuard: true,
            hide: !userHasAccessTo(AccessToggleName.WANTED_PARTS_ACCESS)
          },
          {
            title: t('rentals'),
            icon: ciVehicleRentals,
            url: router.resolve({ name: '__quick_search_rentals' }).href,
            useNuxtLink: true
          },
          {
            title: t('classifieds::xyma'),
            icon: ciXyma,
            url: '/quick-search/xyma/',
            useNuxtLink: true
          },
          {
            title: `${t('real estate')} - Plot.gr`,
            icon: ciRealEstate,
            url: router.resolve({ name: '__quick_search_plot' }).href,
            useNuxtLink: true
          },
          {
            title: t('jobs'),
            icon: ciJobs,
            url: router.resolve({ name: '__classifieds_search_jobs' }).href,
            useNuxtLink: true
          },
          {
            title: t('deals'),
            icon: ciDeals,
            url: router.resolve({ name: '__classifieds_search_deals' }).href,
            useNuxtLink: true
          },
          {
            title: t('car_service'),
            icon: ciCarService,
            url: '/quick-search/service/',
            useNuxtLink: true
          },
          {
            title: t('search classified with id'),
            icon: ciSearch,
            url: router.resolve({ name: '__search_classified_by_id' }).href,
            useNuxtLink: true
          }
        ]
      ]

      menu.value[1].links = [
        [
          {
            title: t('car::vehicles'),
            icon: ciCar,
            url: '/classifieds/cars/new/',
            useNuxtLink: false,
            useAnonGuard: true
          },
          {
            title: t('bike::vehicles'),
            icon: ciMotorcycle,
            url: '/classifieds/bikes/new/',
            useNuxtLink: false,
            useAnonGuard: true
          },
          {
            title: t('commercial::vehicles'),
            icon: ciTruck,
            url: '/classifieds/commercial/new/',
            useNuxtLink: true,
            useAnonGuard: true
          },
          {
            title: t('camper::vehicles'),
            icon: ciCaravan,
            url: '/classifieds/camper/new/',
            useNuxtLink: true,
            useAnonGuard: true
          },
          {
            title: t('boat::vehicles'),
            icon: ciBoat,
            url: router.resolve({
              name: '__classifieds_boats_new_form',
              params: { categoryId: CategoryId.BOATS as string }
            }).href,
            useNuxtLink: true
          },
          {
            title: t('bicycle::vehicles'),
            icon: ciBicycle,
            url: router.resolve({
              name: '__classifieds_bicycles_new_form',
              params: { categoryId: CategoryId.BICYCLES as string }
            }).href,
            useNuxtLink: true
          },
          {
            title: t('hobby::vehicles'),
            icon: ciRc,
            url: '/classifieds/hobby/new/',
            useNuxtLink: false,
            useAnonGuard: true
          }
        ],
        [
          {
            title: `${t('part')}/${t('accessory')}`,
            icon: ciPartsAccessories,
            url: router.resolve({
              name: '__classifieds_parts_new_form',
              params: { categoryId: CategoryId.PARTS as string }
            }).href,
            useNuxtLink: true,
            useAnonGuard: true
          },
          {
            title: t('wanted_parts'),
            icon: ciWantedParts,
            url: router.resolve({
              name: '__classifieds_wanted_parts_new_form',
              params: { categoryId: CategoryId.WANTED_PARTS as string }
            }).href,
            useNuxtLink: true,
            useAnonGuard: true,
            hide: !userHasAccessTo(AccessToggleName.WANTED_PARTS_ACCESS)
          },
          {
            title: t('rent'),
            icon: ciVehicleRentals,
            url: router.resolve({
              name: '__classifieds_rentals_new_form',
              params: { categoryId: CategoryId.RENTALS as string }
            }).href,
            useNuxtLink: true
          },
          {
            title: `${t('real estate::akinito')} - Plot.gr`,
            icon: ciRealEstate,
            url: 'https://www.plot.gr/classifieds/new?vehuser=true',
            externalLink: true,
            useNuxtLink: false
          },
          {
            title: t('classified::xyma'),
            icon: ciXyma,
            url: router.resolve({
              name: '__classifieds_xyma_new_form',
              params: { categoryId: CategoryId.XYMA as string }
            }).href,
            useNuxtLink: true,
            useAnonGuard: true
          },
          {
            title: t('job'),
            icon: ciJobs,
            url: router.resolve({
              name: '__classifieds_jobs_new_form',
              params: { categoryId: CategoryId.JOBS as string }
            }).href,
            useNuxtLink: true
          }
        ]
      ]

      menu.value[2].links = [
        [
          {
            title: t('classifieds promotion'),
            url: router.resolve({ name: '__landing_pages_promotions' }).href,
            useNuxtLink: true
          },
          { title: t('advertise at car'), url: '/advertising/' },
          {
            title: t('audits'),
            url: router.resolve({ name: '__landing_pages_audits' }).href,
            useNuxtLink: true
          },
          { title: t('car_service'), url: '/quick-search/service/' },
          {
            title: t('finance online'),
            url: router.resolve({ name: '__landing_pages_finance' }).href,
            useNuxtLink: true
          },
          {
            title: t('wanted_parts'),
            url: router.resolve({ name: '__landing_pages_wanted_parts' }).href,
            useNuxtLink: true
          }
        ],
        [
          {
            title: 'Forum',
            url: router.resolve({ name: '__qna_view' }).href,
            useNuxtLink: true
          },
          {
            title: t('trades'),
            url: router.resolve({ name: '__trades' }).href,
            useNuxtLink: true
          },
          {
            title: t('dealers catalogue'),
            url: '/dealers/',
            useNuxtLink: true
          },
          {
            title: t('catalog of businesses'),
            url: '/cartalog/categories/',
            useNuxtLink: false
          },
          {
            title: t('used car prices'),
            url: '/classifieds/cars/prices/',
            useNuxtLink: false
          },
          {
            title: t('buy_requests'),
            url: '/landing/sale-requests/',
            useNuxtLink: true,
            hide: !userHasAccessTo(AccessToggleName.SALE_REQUESTS_BUY)
          }
        ]
      ]

      menu.value[3].links = [
        [
          {
            title: t('support'),
            url: router.resolve({ name: '__information_contact' }).href,
            useNuxtLink: true
          },
          {
            title: t('market guide'),
            url: router.resolve({ name: '__information_simboulos' }).href,
            useNuxtLink: true
          },
          {
            title: t('terms and conditions'),
            url: router.resolve({ name: '__information_terms' }).href,
            useNuxtLink: true
          },
          {
            title: t('privacy policy'),
            url: router.resolve({ name: '__information_privacy' }).href,
            useNuxtLink: true
          },
          {
            title: t('job offers'),
            url: router.resolve({ name: '__information_careers' }).href,
            useNuxtLink: true
          },
          {
            title: 'Blog - Car.gr',
            url: 'https://blog.car.gr/',
            useNuxtLink: false
          }
        ],
        [
          {
            title: t('dealer registration'),
            url: router.resolve({ name: '__managers_register' }).href,
            useNuxtLink: true
          },
          {
            title: t('register your business'),
            url: '/cartalog/register/',
            useNuxtLink: false
          },
          {
            title: t('dealers frequently asked questions'),
            url: router.resolve({ name: '__information_dealers_faq' }).href,
            useNuxtLink: true
          }
        ],
        [
          {
            title: t('free user registration'),
            url: userRegisterLink,
            useNuxtLink: false
          },
          {
            title: t('users frequently asked questions'),
            url: router.resolve({ name: '__information_users_faq' }).href,
            useNuxtLink: true
          }
        ]
      ]

      menu.value = { ...menu.value }
    }
    function toggleDropdown(key: number) {
      activeDropdown.value = activeDropdown.value === key ? null : key
    }
    function handleItemClick(link: NavigationLink, event: Event) {
      activeDropdown.value = null

      // Handle external links with modal
      if (link.externalLink && event) {
        event.preventDefault()
        externalLinkMediatorUrl.value = link.url
        showExternalLinkMediatorModal.value = true
      }
    }

    return {
      menu,
      activeDropdown,
      externalLinkMediatorUrl,
      showExternalLinkMediatorModal,
      toggleDropdown,
      handleItemClick
    }
  },
  i18n: defineComponentTranslations({
    general: {
      en: 'General',
      el: 'Γενικά'
    },
    'finance online': {
      en: 'Online finance',
      el: 'Online χρηματοδότηση'
    }
  })
})
