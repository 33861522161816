import { CIcon } from '~/icons/types'

export const ciCheckCircle: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'm14.5 9.501-3.5 3.4-1.5-1.5c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1l2 2c.3.3.8.3 1.1 0l4-4c.3-.3.3-.8 0-1.1-.3-.2-.7-.2-1 .1Z'
    },
    {
      d:
        'M12 2.2c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8Zm0 18c-4.6 0-8.2-3.7-8.2-8.2 0-4.6 3.7-8.2 8.2-8.2 4.6 0 8.2 3.7 8.2 8.2 0 4.6-3.6 8.2-8.2 8.2Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'check-circle',
  type: 'light'
}
