import { CIcon } from '~/icons/types'

export const ciLocationPin: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12 6.5c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4Zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z'
    },
    {
      d:
        'M12 2c-4.7 0-8.5 3.8-8.5 8.5 0 3.9 2.1 7 4 9 1 1 2 1.8 2.7 2.3.4.3.7.5.9.6.1.1.2.1.3.2h.1c.3.2.7.2 1 0h.1c.1 0 .2-.1.3-.2.2-.1.5-.3.9-.6.7-.5 1.7-1.3 2.7-2.3 2-2 4-5.1 4-9C20.5 5.8 16.7 2 12 2Zm3 16.1c-.9.9-1.8 1.6-2.4 2.1-.2.2-.4.3-.6.4-.2-.1-.4-.2-.6-.4-.7-.5-1.5-1.2-2.4-2.1-1.8-1.8-3.5-4.4-3.5-7.6C5.5 6.9 8.4 4 12 4s6.5 2.9 6.5 6.5c0 3.2-1.7 5.8-3.5 7.6Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'location-pin',
  type: 'regular'
}
