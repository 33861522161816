












































































import {
  ref,
  defineComponent,
  computed,
  defineVue3AsyncComponent
} from '~/utils/nuxt3-migration'
import { useDeps } from '~/compositions/dependency-container'
import { useNamespacedStore } from '~/compositions/store'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import RouteGuardService from '~/services/RouteGuardService'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import {
  ADMINUSER_NS,
  AdminUserState
} from '~/store/modules/shared/admin/user/state'
import { ciUserCircle as ciUserCircleRegular } from '~/icons/source/regular/user-circle'
import { ciUserCircle as ciUserCircleSolid } from '~/icons/source/solid/user-circle'
import { ciFolderOpen } from '~/icons/source/regular/folder-open'
import { ciParking } from '~/icons/source/regular/parking'
import { ciParkingP } from '~/icons/source/solid/parking-p'
import { ciHamburger } from '~/icons/source/regular/hamburger'
import { ciArrowAltLeft } from '~/icons/source/solid/arrow-alt-left'
import HeaderLogo from '~/components/car/header/HeaderLogo.vue'
import HeaderNavigationMenu from '~/components/car/header/HeaderNavigationMenu.vue'
import CLink from '~/components/shared/configurable/link/CLink.vue'
import CIcon from '~/components/shared/configurable/CIcon.vue'
import HeaderParking from '~/components/car/header/HeaderParking.vue'
import HeaderCart from '~/components/car/header/HeaderCart.vue'
import HeaderLanguage from '~/components/car/header/HeaderLanguage.vue'

// NOTE: base api here -> https://www.car.gr/api/user/base-page/ user_base_page
// TODOs:
// - Parking and message notifications test with mock data

export default defineComponent({
  components: {
    HeaderLogo,
    HeaderNavigationMenu,
    HeaderParking,
    HeaderCart,
    HeaderLanguage,
    CLink,
    CIcon,
    HeaderAdminTopBar: defineVue3AsyncComponent(() =>
      import('~/components/car/header/HeaderAdminTopBar.vue')
    ),
    HeaderMobileMenu: defineVue3AsyncComponent(() =>
      import('~/components/car/header/HeaderMobileMenu.vue')
    ),
    HeaderUser: defineVue3AsyncComponent(() =>
      import('~/components/car/header/HeaderUser.vue')
    ),
    CExternalLinkMediatorModal: defineVue3AsyncComponent(() =>
      import(
        '~/components/shared/configurable/modal/CExternalLinkMediatorModal.vue'
      )
    )
  },
  props: {
    availableLocales: {
      type: Array,
      required: true
    }
  },
  setup() {
    const [legacyUrlService, routeGuardService] = useDeps(
      LegacyUrlService,
      RouteGuardService
    )
    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const { state: adminUserState } = useNamespacedStore<AdminUserState>(
      ADMINUSER_NS
    )

    const showMobileMenu = ref(false)
    const showExternalLinkMediatorModal = ref(false)
    const externalLinkMediatorUrl = ref('')

    const isAdmin = computed(() => userGetters('isAdmin'))
    const isAnon = computed(() => userGetters('isSingleOrAnon'))

    const adminExtras = computed(() => adminUserState.adminExtras)
    const hasAccessToParking = routeGuardService.userHasAccessToParking()
    const offerBtnLink = legacyUrlService.offerBtnLink()
    const offerBtnText = legacyUrlService.offerBtnText()
    const offerBtnIcon = legacyUrlService.offerBtnIcon()

    const cartItemsCount = computed(() =>
      userGetters('marketplaceCartItemsCount')
    )
    const showCartButton = computed(() => cartItemsCount.value > 0)

    return {
      ciUserCircleRegular,
      ciUserCircleSolid,
      ciFolderOpen,
      ciParking,
      ciParkingP,
      ciHamburger,
      ciArrowAltLeft,
      showMobileMenu,
      showExternalLinkMediatorModal,
      externalLinkMediatorUrl,
      offerBtnLink,
      offerBtnText,
      offerBtnIcon,
      hasAccessToParking,
      isAdmin,
      isAnon,
      adminExtras,
      showCartButton
    }
  }
})
